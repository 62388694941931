import timg1 from '../images/team/hendra.jpg'
import timg2 from '../images/team/kharisma.jpg'
import timg3 from '../images/team/ricky.jpg'
import timg4 from '../images/team/dylia.jpg'
import timg5 from '../images/team/silvia.jpg'
import timg6 from '../images/team/amel.jpg'
import timg8 from '../images/team/ervin.jpg'
import timg9 from '../images/team/rizky.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg1,
      name: 'Lutfy Mahendra',
      slug: 'Atticus-Sterling',
      title: 'Tentor TWK',
   },
   {
      Id: '2',
      tImg: timg2,
      name: 'Kharisma Putri',
      slug: 'Orion-Jasper',
      title: 'Tentor TIU',
   },
   {
      Id: '3',
      tImg: timg3,
      name: 'Muhammad Ricky Suryawan',
      slug: 'August-Everest',
      title: 'Tentor TIU',
   },
   {
      Id: '4',
      tImg: timg4,
      name: 'Dylia Rahmadyanti',
      slug: 'Maverick-Phoenix',
      title: 'Tentor TIU',
   },
   {
      Id: '5',
      tImg: timg5,
      name: 'Silvia Suryaningrum',
      slug: 'Daxton-Atlas',
      title: 'Tentor TWK',
   },
   {
      Id: '6',
      tImg: timg6,
      name: 'Amelia Hapsari',
      slug: 'Maverick-Phoenix',
      title: 'Tentor TIU',
   },
   {
      Id: '8',
      tImg: timg8,
      name: 'Ervin Yunus Evendi',
      slug: 'Maverick-Phoenix',
      title: 'Tentor TWK',
   },
   {
      Id: '9',
      tImg: timg9,
      name: 'Rizqy Abdhullah Putra',
      slug: 'Maverick-Phoenix',
      title: 'Tentor TIU',
   }
]

export default Teams;