import React from 'react';
import PartnerSection from '../PartnerSection';
import CountUp from 'react-countup';
import sIcon1 from '../../images/icons/icon_lolos.svg'
import sIcon2 from '../../images/icons/icon_tahun.svg'
import sIcon3 from '../../images/icons/icon_sekolah.svg'
import sIcon4 from '../../images/icons/icon_siswapuass.svg'
import Bg from '../../images/shapes/bg_pattern_1.svg'
import fimg from '../../images/about/foto_testi.jpg'

const FunFact = [
    {
        title: '290',
        subTitle: 'Siswa DELTA telah lolos ke Sekolah Kedinasan',
        symbol: '+',
        icon: sIcon1,
    },
    {
        title: '8',
        subTitle: 'Tahun berpengalaman meloloskan ke Sekolah Kedinasan',
        symbol: '+',
        icon: sIcon2,
    },
    {
        title: '15',
        subTitle: 'Sekolah di Jawa Timur telah bekerjasama dengan DELTA',
        symbol: '+',
        icon: sIcon3,
    },
    {
        title: '400',
        subTitle: 'Siswa puas belajar bersama DELTA (berdasarkan review Google Maps',
        symbol: '+',
        icon: sIcon4,
    },


]


const FeaturesSection = (props) => {

    return (
        <section className="client_logo_section section_space" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="section_space pt-0">
                    <div className="heading_block text-center">
                        <div className="heading_focus_text mb-0">
                            <span className="badge bg-secondary text-white">Sekolah Kedinasan</span>
                            Yang berhasil diloloskan DELTA
                        </div>
                    </div>
                    <PartnerSection />
                </div>

                <div className="row funfact_wrapper">
                    <div className="col-lg-8">
                        <div className="row">
                            {FunFact.map((funfact, fitem) => (
                                <div className="col-md-6" key={fitem}>
                                    <div className="funfact_block">
                                        <div className="funfact_icon">
                                            <img src={funfact.icon} alt="Techco - SVG Icon Head" />
                                        </div>
                                        <div className="funfact_content">
                                            <div className="counter_value">
                                                <span className="odometer" data-count="25"><CountUp end={funfact.title} enableScrollSpy /></span>
                                                <span>{funfact.symbol}</span>
                                            </div>
                                            <h3 className="funfact_title mb-auto">{funfact.subTitle}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="col-xl-4 h-100">
                        <a href="https://www.youtube.com/@bimbeldeltapkn-stan1871" target="_blank" rel="noopener noreferrer" className="our_world_employees">
                            <div className="image_wrap">
                                <img src={fimg} alt="Techco - Employees Group" />
                            </div>
                            <div className="content_wrap">
                                <h2 className="title_text mb-auto">
                                    <b className="d-block">Lihat testimoni Alumni di Channel Youtube kami! </b> 
                                </h2>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeaturesSection;