import React, { Fragment, useState } from 'react';
import Project from '../../api/project';
import { Link } from "react-router-dom";
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import CtaSection from '../../components/CtaSection/CtaSection';

const PortfolioPage = (props) => {

    const [activeFilter, setActiveFilter] = useState('all');

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
    }

    const filteredProjects = activeFilter === 'all'
        ? Project
        : Project.filter(project => project.sekolahKedinasan === activeFilter);

    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Alumni DELTA'} />
                <section className="portfolio_section section_space bg-light">
                    <div className="container">
                        <div className="filter_elements_nav">
                            <ul className="unordered_list justify-content-center">
                                <li className={activeFilter === 'all' ? 'active' : ''} onClick={() => handleFilterClick('all')}>Lihat Semua</li>
                                <li className={activeFilter === 'IPDN' ? 'active' : ''} onClick={() => handleFilterClick('IPDN')}>IPDN</li>
                                <li className={activeFilter === 'PKN STAN' ? 'active' : ''} onClick={() => handleFilterClick('PKN STAN')}>PKN STAN</li>
                                <li className={activeFilter === 'POLSTAT STIS' ? 'active' : ''} onClick={() => handleFilterClick('POLSTAT STIS')}>POLSTAT STIS</li>
                                <li className={activeFilter === 'KEMENHUB' ? 'active' : ''} onClick={() => handleFilterClick('KEMENHUB')}>KEMENHUB</li>
                                <li className={activeFilter === 'POLTEKIM' ? 'active' : ''} onClick={() => handleFilterClick('POLTEKIM')}>POLTEKIM</li>
                                <li className={activeFilter === 'POLTEKIP' ? 'active' : ''} onClick={() => handleFilterClick('POLTEKIP')}>POLTEKIP</li>
                                <li className={activeFilter === 'STMKG' ? 'active' : ''} onClick={() => handleFilterClick('STMKG')}>STMKG</li>
                                <li className={activeFilter === 'UNHAN' ? 'active' : ''} onClick={() => handleFilterClick('UNHAN')}>UNHAN</li>
                            </ul>
                        </div>
                        <div className="filter_elements_wrapper row">
                            {filteredProjects.map((project, prj) => (
                                <div className="col-lg-4" key={prj}>
                                    <div className="portfolio_block portfolio_layout_2">
                                        <div className="portfolio_image">
                                            <Link onClick={ClickHandler} className="portfolio_image_wrap bg-light" to={`/alumni/${project.Id}`}>
                                                <img src={project.pImg} alt="Mobile App Design" />
                                            </Link>
                                        </div>
                                        <div className="portfolio_content">
                                            <h3 className="portfolio_title">
                                                <Link onClick={ClickHandler} to={`/alumni/${project.Id}`}>
                                                    {project.nama}
                                                </Link>
                                            </h3>
                                            <ul className="category_list unordered_list">
                                                <li><Link onClick={ClickHandler} to={`/alumni/${project.Id}`}><i className="fa-solid fa-tags"></i> {project.sekolahKedinasan}</Link></li>
                                                <li><Link onClick={ClickHandler} to={`/alumni/${project.Id}`}><i className="fa-solid fa-building"></i> {project.AsalSekolah}</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <CtaSection />
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default PortfolioPage;