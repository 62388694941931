import React from 'react';
import tImg1 from '../../images/avatar/alumni_ully.png'
import tImg2 from '../../images/avatar/alumni_dita.png'
import tImg3 from '../../images/avatar/alumni_dik.png'
import tImg4 from '../../images/avatar/alumni_dodi.png'
import Bg from '../../images/shapes/bg_pattern_2.svg'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"



const testimonial = [
    {
        id: '01',
        tImg:tImg1,
        Title:'Fasilitas Lengkap',
        Des: "DELTA sangat membantu saya dalam setiap proses tesnya. Fasilitasnya lengkap, tentornya juga nggak pelit dalam memberi materi dan konsultasi.",
        Name: 'Ully Prisca',
        sub:'POLSTAT STIS',
    },
    {
        id: '02',
        tImg:tImg2,
        Title:'Tentor Ramah dan Komunikatif',
        Des: "Tentornya Enak Banget, Ramah, dan Komunikatif. Kalau konsultasi juga seru jadi nggak merasa bosen",
        Name: 'Dita Ayu K.',
        sub:'PKN STAN',
    },
    {
        id: '03',
        tImg:tImg3,
        Title:'Soal Latihan Mendekati Soal Test SKD',
        Des: "Soal Latihannya beneran mendekati soal test SKD. Ini sangat membantu dan hasilnya sangat memuaskan, terimakasih DELTA!.",
        Name: 'Diksangga Tentra',
        sub:'PKN STAN',
    },
    {
        id: '04',
        tImg:tImg4,
        Title:'Persiapan Test Dibimbing Dengan Baik',
        Des: "DELTA Bimbel membimbing saya dengan baik. Persiapan untuk test pun dibimbing, dan akhirnya saya berhasil lolos seleksi IPDN.",
        Name: 'Dody Elza F. ',
        sub:'IPDN',
    }
]

const Testimonial = () => {

    return (

        <section className="review_section section_space bg-light" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="heading_block text-center">
                    <div className="heading_focus_text">
                        <span className="badge bg-secondary text-white">Testimoni</span>
                        Alumni
                    </div>
                    <h2 className="heading_text mb-0">
                        Apa Kata Mereka Tentang DELTA?
                    </h2>
                </div>

                <div className="row">
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 1, 991: 2 }}>
                        <Masonry columnsCount={4} gutter="30px">
                            {testimonial.map((testimonial, tsm) => (
                                <div className="review_block pb-0" key={tsm}>
                                    <h3 className="review_title">“{testimonial.Title}”</h3>
                                    <p className="review_commtent">
                                        {testimonial.Des}
                                    </p>
                                    <div className="review_admin">
                                        <div className="review_admin_image">
                                            <img src={testimonial.tImg} alt="Roman Dymtro" />
                                        </div>
                                        <div className="review_admin_info">
                                            <h4 className="review_admin_name">{testimonial.Name}</h4>
                                            <span className="review_admin_designation">{testimonial.sub}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;