import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import Teams from '../../api/team'
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import CtaSection from '../../components/CtaSection/CtaSection';
import tImg from '../../images//team/tentor_collage.jpg'

const TeamPage = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Tentor DELTA'} pageTop={'Master'} />
                <section className="about_section section_space bg-light">
                    <div className="container">
                        <div className="row align-items-center justify-content-lg-between">
                            <div className="col-lg-6 order-lg-last">
                                <div className="team_cartoon_image">
                                    <img src={tImg} alt="Team Cartoon" />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="about_content">
                                    <div className="heading_block">
                                        <h2 className="heading_text">
                                            Tentor Profesional
                                        </h2>
                                        <p className="heading_description mb-0">
                                            Pembelajaran Akademik DELTA dibimbing oleh tentor tentor profesional dengan banyak pengalaman mengajar yang berasal dari berbagai Universitas terbaik di berbagai daerah di Indonesia
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="team_section section_space">
                    <div className="container">
                        <div className="heading_block text-center">
                            <h2 className="heading_text mb-0">
                                Master Tentor DELTA
                            </h2>
                        </div>

                        <div className="row">
                            {Teams.map((team, tm) => (
                                <div className="col-lg-4 col-md-6 col-sm-6" key={tm}>
                                    <div className="team_block">
                                        <div className="team_member_image">
                                            <div className="image_wrap" aria-label="Team Details Button">
                                                <img src={team.tImg} alt="" />
                                            </div>
                                        </div>
                                        <div className="team_member_info">
                                            <h3 className="team_member_name">
                                                <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>{team.name}</Link>
                                            </h3>
                                            <h4 className="team_member_designation">{team.title}</h4>
                                            <ul className="social_icons_block unordered_list justify-content-center">
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <CtaSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default TeamPage;
