import React from 'react';
import Teams from '../../api/team'
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

const TeamSection = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="team_section xb-hidden section_space">
            <div className="container">
                <div className="heading_block text-center">
                    <div className="heading_focus_text">
                        <span className="badge bg-secondary text-white">Dibimbing</span>
                        Dengan 
                    </div>
                    <h2 className="heading_text mb-0">
                        Tentor Tentor Terbaik 
                    </h2>
                </div>

                <div className="team_carousel">
                    <Swiper
                        loop={true}
                        spaceBetween={30}
                        allowTouchMove={true}
                        centeredSlides={true}
                        pagination={{ clickable: true }}
                        speed={800}
                        breakpoints={{
                            576: {
                                slidesPerView: 2,
                            },
                            1025: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {Teams.map((team, tm) => (
                            <SwiperSlide key={tm}>
                                <div className="team_block">
                                    <div className="team_member_image">
                                        <Link className="image_wrap" aria-label="Team Details Button">
                                            <img src={team.tImg} alt="" />
                                        </Link>
                                    </div>
                                    <div className="team_member_info">
                                        <h2 className="team_member_name">
                                            <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>{team.name}</Link>
                                        </h2>
                                        <h3 className="team_member_designation">{team.title}</h3>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <div className="container">
                    <div className="btns_group pb-0">
                        <Link className="btn btn-outline-light" to="/tentor">
                            <span className="btn_label" data-text="Lihat Semua Tentor">Lihat Semua Tentor</span>
                            <span className="btn_icon">
                                <i className="fa-solid fa-arrow-up-right"></i>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TeamSection;