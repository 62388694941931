import React from 'react';
import Project from '../../api/project';
import { Link } from "react-router-dom";
import { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './responsive.css';

const ProjectSection = () => {
    const ClickHandler = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <section className="portfolio_section xb-hidden section_space pb-0">
            <div className="container">
                <div className="heading_block">
                    <div className="row align-items-end">
                        <div className="col-lg-7">
                            <div className="heading_focus_text">
                                <span className="badge bg-secondary text-white"><b>TERBUKTI</b></span>
                                Berhasil meloloskan RATUSAN siswa ke Sekolah Kedinasan
                            </div>
                            <h2 className="heading_text">
                                Alumni DELTA
                            </h2>
                            <p className="heading_description mb-0">
                                Bersama DELTA, kami telah membantu banyak siswa mencapai impian mereka di sekolah kedinasan. Are you gonna be the next?
                            </p>
                        </div>
                        <div className="col-lg-5 d-none d-lg-flex justify-content-end text-center">
                            <Link onClick={ClickHandler} to="/alumni" className="btn btn-primary">
                                <span className="btn_label" data-text="Lihat Alumni">Lihat Alumni</span>
                                <span className="btn_icon">
                                    <i className="fa-solid fa-arrow-up-right"></i>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio_carousel">
                <Swiper
                    modules={[Pagination, A11y]}
                    slidesPerView={1}
                    loop={true}
                    spaceBetween={20}  
                    centeredSlides={true}
                    pagination={{ clickable: true }}
                    autoplay={{ delay: 2500, disableOnInteraction: false }}
                    speed={600}
                    breakpoints={{
                        1025: {
                            slidesPerView: 3,  
                            spaceBetween: 40,  
                        },
                        768: {
                            slidesPerView: 2,  
                            spaceBetween: 20,  
                        },
                        576: {
                            slidesPerView: 1,  
                            spaceBetween: 10,  
                        }
                    }}
                >
                    {Project.slice(0, 5).map((project, prj) => (
                        <SwiperSlide key={prj}>
                            <div className="portfolio_block">
                                <div className="portfolio_image">
                                    <Link onClick={ClickHandler} className="portfolio_image_wrap bg-light" to="/alumni">
                                        <img src={project.pImg} alt={project.nama} />
                                    </Link>
                                </div>
                                <div className="portfolio_content">
                                    <h3 className="portfolio_title">
                                        <Link onClick={ClickHandler} to="/alumni">
                                            {project.nama}
                                        </Link>
                                    </h3>
                                    <ul className="category_list unordered_list">
                                        <li><Link onClick={ClickHandler} to="/alumni">{project.sekolahKedinasan}</Link></li>
                                    </ul>
                                    <Link onClick={ClickHandler} className="btn btn-outline-light" to={`/portfolio_details/${project.sekdin}`} />
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <div className="container text-center d-block d-lg-none">
                <div className="btns_group pb-0">
                    <Link onClick={ClickHandler} className="btn btn-primary" to="/alumni">
                        <span className="btn_label" data-text="Lihat Alumni">Lihat Alumni</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default ProjectSection;
