import React, { Fragment, useState } from 'react';
import Services from '../../api/service';
import { useParams, Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import WhyUs from '../../main-component/AboutUsPage/WhyUs.js';
import CtaSection from '../../components/CtaSection/CtaSection';
import ProcessTechnology from '../../components/software-company-components/ProcessTechnology/ProcessTechnology';
import srImg from '../../images/services/image_program.jpg';
import srImg2 from '../../images/services/web_cat.jpg';
import srImg3 from '../../images/services/web_fisik.jpg';
import srImg4 from '../../images/services/web_konsul.jpg';

const ServiceSinglePage = (props) => {

    const { slug } = useParams();

    const ServiceDetails = Services.find(item => item.slug === slug);

    const [isOpen, setOpen] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <Header />
            <main className="page_content service-single-page">
                <PageTitle pageTitle={ServiceDetails.title} />
                <section className="service_details_section section_space bg-light">
                    <div className="container">
                        <div className="details_item_image position-relative">
                            <img src={srImg} alt="Service Details" />
                        </div>
                        <h2 className="details_item_title">{ServiceDetails.title}</h2>
                        {/* Service description conditionally rendered */}
                        {ServiceDetails.title === 'Program Persiapan Sekolah Kedinasan' ? (
                            <p>
                                Program Persiapan Sekolah Kedinasan adalah program yang diselenggarakan oleh Bimbel DELTA yang bertujuan untuk membantu siswa-siswi yang ingin melanjutkan pendidikan ke sekolah kedinasan. Program ini memberikan pembekalan materi dan simulasi tes yang akan diujikan pada seleksi masuk sekolah kedinasan. Program ini diikuti oleh siswa-siswi yang berminat melanjutkan pendidikan ke sekolah kedinasan seperti STAN, IPDN, dan Sekolah Tinggi Intelijen Negara (STIN).
                            </p>
                        ) : ServiceDetails.title === 'Program Persiapan Universitas Pertahanan' ? (
                            <p>
                                Program Persiapan Universitas Pertahanan adalah program yang diselenggarakan oleh Bimbel DELTA yang bertujuan untuk membantu siswa-siswi yang ingin melanjutkan pendidikan ke Universitas Pertahanan...
                            </p>
                        ) : (
                            <p>Paragraf Default</p>
                        )}

                        <ProcessTechnology />
                        
                        {/* Image Grid */}
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="details_item_image m-0">
                                    <img src={srImg2} alt="Service Details" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="details_item_image m-0">
                                    <img src={srImg3} alt="Service Details" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="details_item_image m-0">
                                    <img src={srImg4} alt="Service Details" />
                                </div>
                            </div>
                        </div>

                        <div className="text-center mt-5"> 
                            <Link
                                to="/pricing" 
                                className="btn btn-primary"
                                onClick={ClickHandler}
                                style={{ display: 'inline-block' }} // Ensure the button is inline-block for centering
                            >
                                <span className="btn_label" data-text="Daftar Harga">Daftar Harga</span>
                                <span className="btn_icon">
                                    <i className="fa-solid fa-arrow-up-right"></i>
                                </span>
                            </Link>
                        </div>
                    </div>
                </section>
            </main>
            <WhyUs />
            <CtaSection />
            <Footer />
            <Scrollbar />
            <ModalVideo channel="youtube" autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
        </Fragment>
    );
};

export default ServiceSinglePage;
