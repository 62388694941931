import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App2.css";

const Facilities = () => {
  const cards = [
    {
      icon: "💬",
      title: "Tryout dengan Sistem CAT",
      description:
        "Sistem pembelajaran di DELTA menggunakan sistem CAT sehingga dibuat semirip mungkin dengan sistem yang ada di BKN.",
    },
    {
      icon: "📝",
      title: "Pendampingan Test",
      description:
        "Kami siap mendampingi siswa mulai dari tahapan pendaftaran, seleksi lanjutan sampai dengan pendaftaran ulang.",
    },
    {
      icon: "⚡",
      title: "Pelatihan Persiapan Test",
      description:
        "Kami menyiapkan siswa untuk menghadapi berbagai rangkaian test seleksi kedinasan baik Test SKD, Test Fisik, Wawancara hingga Psikotest.",
    },
    {
      icon: "💻",
      title: "Fasilitas Pembelajaran",
      description:
        "Tersedia AC dan WiFi yang dapat digunakan siswa untuk pembelajaran serta memberi kenyamanan lebih dalam belajar.",
    },
  ];

  return (
    <div className="container py-5">
      <div className="row mb-3">
        <div className="col-12">
          <h2 className="text-primary" style={{ fontSize: "2.5rem" }}>
            Apa saja fasilitas yang kamu dapatkan?
          </h2>
        </div>
      </div>
      <div className="row g-3">
        {cards.map((card, index) => (
          <div className="col-md-6" key={index}>
            <div
              className="card text-white shadow-lg h-100"
              style={{
                borderRadius: "20px",
                background: "linear-gradient(135deg, #8E2DE2, #4A00E0)", // Gradient warna
              }}
            >
              <div
                className="card-body d-flex flex-column justify-content-center align-items-start"
                style={{ minHeight: "250px", padding: "2rem" }}
              >
                <div className="fs-1 mb-3">{card.icon}</div>
                <h5 className="card-title">{card.title}</h5>
                <p className="card-text">{card.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Facilities;
