// images
import blogImg1 from "../images/blog/cab_sda.jpg";
import blogImg2 from "../images/blog/cab_sub.jpg";
import blogImg3 from "../images/blog/cab_mjk.jpg";
import blogImg4 from "../images/blog/cab_kdr.jpg";

// images
import blogsImg1 from "../images/blog/cab_sda.jpg";
import blogsImg2 from "../images/blog/cab_sub.jpg";
import blogsImg3 from "../images/blog/cab_mjk.jpg";
import blogsImg4 from "../images/blog/cab_kdr.jpg";


const blogs = [
    {
        id: '1',
        title: 'DELTA Sidoarjo',
        slug:'delta-sidoarjo',
        screens: blogImg1,
        bSingle: blogsImg1,
        alamat: 'Perumahan Graha Kuncara Blok M-1 Kemiri Sidoarjo',
        whatsapp: ' 0896 7661 1116',
        instagram:' deltabimbel',
        maps: 'https://maps.app.goo.gl/NYTz3kzUvQDeCKca9',
        comment:'35',
        thumb:'Branding',
        blClass:'format-standard-image',
    },
    {
        id: '2',
        title: 'DELTA Surabaya',
        slug:'delta-sub',
        screens: blogImg2,
        bSingle: blogsImg2,
        alamat: 'Jl. Jemursari 2 No. 32 Surabaya',
        whatsapp: ' 0813 5944 8913',
        instagram:' deltabimbel_sub',
        maps: 'https://maps.app.goo.gl/PC3aBT7u9JkQrKRA8',
        comment:'80',
        thumb:'Mobile App',
        blClass:'format-standard-image',
    },
    {
        id: '3',
        title: 'DELTA Mojokerto',
        slug: 'delta-mojokerto',
        screens: blogImg3,
        bSingle: blogsImg3,
        alamat: 'Puri Mojopahit Blok B-05 Kec.Puri Kab.Mojokerto',
        whatsapp: ' 0821 3240 0945',
        instagram:' deltabimbel_mojokerto',
        maps: 'https://maps.app.goo.gl/HwX7qw3GcM4ApWVm6',
        comment:'95',
        thumb:'Technology',
        blClass:'format-video',
    },
    {
        id: '4',
        title: 'DELTA Kediri',
        slug: 'delta-kediri',
        screens: blogImg4,
        bSingle: blogsImg4,
        alamat: 'Jl.Budaya Cipta Kec.Ngasem Kab.Kediri.',
        whatsapp: ' 0822 2853 2228',
        instagram:' deltabimbel_kediri',
        maps: 'https://maps.app.goo.gl/9YxN49r7T5Y7JH76A',
        comment:'95',
        thumb:'Consulting',
    }
];
export default blogs;