import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import logo from '../../images/site_logo/delta_logo.png'
import MobileMenu from '../MobileMenu/MobileMenu'

const Header = (props) => {

    const [mobailActive, setMobailState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (

        <header className="site_header site_header_1">
            <div className="header_top text-center">
                <div className="container">
                    <p className="m-1">Program Persiapan Kedinasan 2025 Telah Dibuka! Khusus kelas XII/Alumni. Harga Mulai <b>8 Juta Rupiah!</b> <Link onClick={ClickHandler} to="/pricing"><u>DAFTAR SEKARANG</u> <i className="fa-solid fa-angle-right"></i></Link></p>
                </div>
            </div>
            <div className={`header_bottom stricky  ${isSticky ? 'stricked-menu stricky-fixed' : ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-2 col-5">
                            <div className="site_logo">
                                <Link onClick={ClickHandler} className="site_link" to="/">
                                    <img src={logo} alt="Site Logo – Techco – IT Solutions & Technology, Business Consulting, Software Company Site Template" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-2">
                            <nav className="main_menu navbar navbar-expand-lg">
                                <div className="main_menu_inner collapse navbar-collapse justify-content-lg-center" id="main_menu_dropdown">
                                    <ul className="main_menu_list unordered_list justify-content-center">
                                            <Link onClick={ClickHandler} to="/" id="home" role="button" aria-expanded="true">
                                                Home
                                            </Link>
                                        <li className="dropdown">
                                            <Link onClick={ClickHandler} className="nav-link" to="/service" id="company_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Program
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="company_submenu">
                                                <li><Link onClick={ClickHandler} to="/service-single/program-kedinasan">Program Sekolah Kedinasan</Link></li>
                                                <li><Link onClick={ClickHandler} to="/service-single/program-cpns">Program CPNS</Link></li>
                                                <li><Link onClick={ClickHandler} to="/service-single/program-unhan">Program Persiapan UNHAN</Link></li>
                                                <li><Link onClick={ClickHandler} to="/service-single/program-akmil-akpol">Program Akmil & Akpol</Link></li>
                                                <li><Link onClick={ClickHandler} to="/service-single/online-class">Program Online Class</Link></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown">
                                            <Link onClick={ClickHandler} className="nav-link" to="/" id="portfolio_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Alumni
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="portfolio_submenu">
                                                <li><Link onClick={ClickHandler} to="/alumni">Alumni Sekolah Kedinasan</Link></li>
                                                <li><Link onClick={ClickHandler} to="/alumni">Alumni CPNS</Link></li>
                                            </ul>
                                        </li>
                                        <li className="main_menu_list unordered_list justify-content-center">
                                            <Link onClick={ClickHandler} to="/about" id="services_submenu" role="button" aria-expanded="true">
                                                Tentang Kami
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-5">
                            <ul className="header_btns_group unordered_list justify-content-end">
                                <li>
                                    <button className="mobile_menu_btn" onClick={() => setMobailState(!mobailActive)} type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="far fa-bars"></i>
                                    </button>
                                </li>
                                <li>
                                    <a href="https://online.delta-stan.com" target="_blank" rel="noopener noreferrer" onClick={ClickHandler} className="btn btn-outline-light">     
                                        <span className="btn_label" data-text="Login Siswa">Login Siswa</span>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mobail-wrap">
                    <div className={`mobail-menu ${mobailActive ? "active" : ""}`}>
                        <div className="xb-header-menu-scroll">
                            <div className="xb-menu-close xb-hide-xl xb-close" onClick={() => setMobailState(!mobailActive)}></div>
                            <nav className="xb-header-nav">
                                <MobileMenu />
                            </nav>
                        </div>
                    </div>
                    <div className="xb-header-menu-backdrop" onClick={() => setMobailState(false)}></div>
                </div>
            </div>
        </header>

    )
}

export default Header;