
import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_line_2.svg'
import shape2 from '../../../images/shapes/shape_line_3.svg'
import shape3 from '../../../images/shapes/shape_line_4.svg'
import shape4 from '../../../images/shapes/shape_space_3.svg'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="process_technology_review_section bg-light section_decoration">
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                        <div className="heading_block">
                            <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                                DELTA Smart Solution
                            </div>
                            <h2 className="heading_text mb-0">
                                Bagaimana <mark>DELTA</mark> membimbing siswa ?
                            </h2>
                        </div>
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="1">
                                    01. Konsultasi Pemilihan Sekdin
                                </AccordionHeader>
                                <AccordionBody accordionId="1" className='acc_body'>
                                    <p className="m-0">
                                        Konsultasi Pemilihan Sekdin adalah tahap awal yang dilakukan oleh tim DELTA untuk membantu siswa memilih sekolah kedinasan yang sesuai dengan minat, kondisi fisik dan kemampuan mereka.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="2">
                                    02. Bimbingan Fisik Rutin setiap Akhir Pekan
                                </AccordionHeader>
                                <AccordionBody accordionId="2" className='acc_body'>
                                    <p className="m-0">
                                        Bimbingan fisik rutin setiap akhir pekan adalah tahap selanjutnya yang dilakukan oleh Tentor Fisik DELTA untuk membantu siswa dalam meningkatkan kesehatan fisik mereka sebagai persiapan tahapan seleksi fisik Sekdin.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="3">
                                    03. Tahapan Materi, Drilling & Intensif
                                </AccordionHeader>
                                <AccordionBody accordionId="3" className='acc_body'>
                                    <p className="m-0">
                                        DELTA membimbing siswanya dengan tahapan materi, drilling dan intensif yang disesuaikan dengan materi seleksi Sekdin. Materi yang diberikan oleh DELTA adalah materi yang sesuai dengan kisi kisi terbaru SKD oleh BKN.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="4">
                                    04. Pembelajaran dengan sistem CAT
                                </AccordionHeader>
                                <AccordionBody accordionId="4" className='acc_body'>
                                    <p className="m-0">
                                        Pembelajaran di DELTA menggunakan sistem CAT (Computer Assisted Test) sehingga siswa dapat terbiasa dengan sistem ujian CAT yang digunakan oleh BKN.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="5">
                                    05. Pendampingan Pendaftaran dan Tahapan Seleksi
                                </AccordionHeader>
                                <AccordionBody accordionId="5" className='acc_body'>
                                    <p className="m-0">
                                        Tidak hanya dari segi akademik, DELTA juga siap membantu siswa mulai dari tahapan pendaftaran hingga pendampingan seleksi lanjutan sampai dengan tahapan pendaftaran ulang.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    <div className="col-lg-5">
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <ul className="content_layer_group unordered_list_block text-center">
                                <li><AccordionHeader targetId="1"><span>Konsultasi Sekdin</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="2"><span>Bimbingan Fisik</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="3"><span>Sistem Akademik DELTA</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="4"><span>Pembelajaran dengan CAT</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="5"><span>Pendampingan Seleksi</span></AccordionHeader></li>
                            </ul>
                        </Accordion>
                    </div>
                </div>
            </div>

            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_3">
                <img src={shape3} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_4">
                <img src={shape4} alt="Techco Shape" />
            </div>
        </section>
    )
};
export default FaqSection;
