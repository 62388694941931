import React from 'react';
import sIcon1 from '../../images/icons/icon_free.png'
import sIcon2 from '../../images/icons/icon_kerja.png'
import sIcon3 from '../../images/icons/logo_korpri.png'

const Policy = [
    {
        title: 'Bebas Biaya Pendidikan',
        subTitle: 'Setelah Kalian lulus kalian akan langsung bekerja di Instansi terkait! Jadi tidak perlu repot repot mencari kerja.',
        icon: sIcon1,
    },
    {
        title: 'Lulus Langsung Bekerja',
        subTitle: 'Setelah Kalian lulus kalian akan langsung bekerja di Instansi terkait! Jadi tidak perlu repot repot mencari kerja.',
        icon: sIcon2,
    },
    {
        title: 'Otomatis Diangkat Menjadi ASN',
        subTitle: 'Alumni Sekolah Kedinasan akan langsung diangkat menjadi PNS setelah lulus dengan sistem ikatan dinas, Jadi tidak perlu ujian CPNS lagi.',
        icon: sIcon3,
    },


]


const PolicySection = (props) => {

    return (
        <section className="policy_section">
            <div className="container">
                <div className="row">
                    {Policy.map((policy, pitem) => (
                        <div className="col-lg-4" key={pitem}>
                            <div className="iconbox_block layout_icon_left">
                                <div className="iconbox_icon bg-secondary-subtle">
                                    <img src={policy.icon} alt="Dollar SVG Icon" />
                                </div>
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">{policy.title}</h3>
                                    <p className="mb-0">
                                        {policy.subTitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PolicySection;