import pImg1 from '../images/portfolio/1.jpg'
import pImg2 from '../images/portfolio/2.jpg'
import pImg3 from '../images/portfolio/3.jpg'
import pImg4 from '../images/portfolio/4.jpg'
import pImg5 from '../images/portfolio/5.jpg'
import pImg6 from '../images/portfolio/6.jpg'
import pImg7 from '../images/portfolio/7.jpg'
import pImg8 from '../images/portfolio/8.jpg'
import pImg9 from '../images/portfolio/9.jpg'
import pImg10 from '../images/portfolio/10.jpg'
import pImg11 from '../images/portfolio/11.jpg'
import pImg12 from '../images/portfolio/12.jpg'
import pImg13 from '../images/portfolio/13.jpg'
import pImg14 from '../images/portfolio/14.jpg'
import pImg15 from '../images/portfolio/15.jpg'
import pImg16 from '../images/portfolio/16.jpg'
import pImg17 from '../images/portfolio/17.jpg'
import pImg18 from '../images/portfolio/18.jpg'
import pImg19 from '../images/portfolio/19.jpg'
import pImg20 from '../images/portfolio/20.jpg'
import pImg21 from '../images/portfolio/21.jpg'
import pImg22 from '../images/portfolio/22.jpg'
import pImg23 from '../images/portfolio/23.jpg'
import pImg24 from '../images/portfolio/24.jpg'
import pImg25 from '../images/portfolio/25.jpg'
import pImg26 from '../images/portfolio/26.jpg'
import pImg27 from '../images/portfolio/27.jpg'
import pImg28 from '../images/portfolio/28.jpg'
import pImg29 from '../images/portfolio/29.jpg'
import pImg30 from '../images/portfolio/30.jpg'
import pImg31 from '../images/portfolio/31.jpg'
import pImg32 from '../images/portfolio/32.jpg'
import pImg33 from '../images/portfolio/33.jpg'
import pImg34 from '../images/portfolio/34.jpg'
import pImg35 from '../images/portfolio/35.jpg'
import pImg36 from '../images/portfolio/36.jpg'
import pImg37 from '../images/portfolio/37.jpg'
import pImg38 from '../images/portfolio/38.jpg'
import pImg39 from '../images/portfolio/39.jpg'
import pImg40 from '../images/portfolio/40.jpg'
import pImg41 from '../images/portfolio/41.jpg'
import pImg42 from '../images/portfolio/42.jpg'
import pImg43 from '../images/portfolio/43.jpg'
import pImg44 from '../images/portfolio/44.jpg'
import pImg45 from '../images/portfolio/45.jpg'
import pImg46 from '../images/portfolio/46.jpg'
import pImg47 from '../images/portfolio/47.jpg'
import pImg48 from '../images/portfolio/48.jpg'
import pImg49 from '../images/portfolio/49.jpg'
import pImg50 from '../images/portfolio/50.jpg'
import pImg51 from '../images/portfolio/51.jpg'
import pImg52 from '../images/portfolio/52.jpg'
import pImg53 from '../images/portfolio/53.jpg'
import pImg54 from '../images/portfolio/54.jpg'
import pImg55 from '../images/portfolio/55.jpg'
import pImg56 from '../images/portfolio/56.jpg'
import pImg57 from '../images/portfolio/57.jpg'
import pImg58 from '../images/portfolio/58.jpg'
import pImg59 from '../images/portfolio/59.jpg'
import pImg60 from '../images/portfolio/60.jpg'
import pImg61 from '../images/portfolio/61.jpg'
import pImg62 from '../images/portfolio/62.jpg'
import pImg63 from '../images/portfolio/63.jpg'
import pImg64 from '../images/portfolio/64.jpg'
import pImg65 from '../images/portfolio/65.jpg'


const Project = [
    {
        Id: '1',
        pImg: pImg1,
        nama: 'Bagas Bintang Saputra',
        AsalSekolah : 'SMAN 1 SIDOARJO',
        sekolahKedinasan : 'IPDN',
        sekdin:'IPDN'
    },
    {
        Id:'2',
        pImg: pImg2,
        nama: 'Dody Elza Firman Cahyo',
        AsalSekolah : 'SMA TARUNA NALA',
        sekolahKedinasan : 'IPDN',
        sekdin:'IPDN'
    },
    {
        Id:'3',
        pImg: pImg3,
        nama: 'Joan Elvi Shannoer Tande',
        AsalSekolah : 'SMAN 5 TARUNA BRAWIJAYA',
        sekolahKedinasan : 'STAN',
        sekdin:'STAN'
    },
    {
        Id:'4',
        pImg: pImg4,
        nama: 'Sq Talenta Mogha Dberkhai',
        AsalSekolah : 'SMAN 2 SURABAYA',
        sekolahKedinasan : 'UNHAN',
        sekdin:'UNHAN'
    },
    {
        Id:'5',
        pImg: pImg5,    
        nama: 'Elis Panagata',
        AsalSekolah : 'SMA ADHILUHUR NABIRE',
        sekolahKedinasan : 'STMKG',
        sekdin:'STMKG'
    },
    {
        Id:'6',
        pImg: pImg6,
        nama: 'Fernanda Ezra Afiawan',
        AsalSekolah : 'SMAN 1 DURENAN',
        sekolahKedinasan : 'IPDN',
        sekdin:'IPDN'
    },
    {
        Id:'7',
        pImg: pImg7,
        nama: 'Arya Wibiano Putra Sakti',
        AsalSekolah : 'MBI Amanatul Ummah',
        sekolahKedinasan : 'IPDN',
        sekdin:'IPDN'
    },
    {
        Id:'8',
        pImg: pImg8,
        nama: 'Egalita Adliyah Baiquni',
        AsalSekolah : 'SMAN 5 Surabaya',
        sekolahKedinasan : 'IPDN',
        sekdin:'IPDN'
    },
    {
        Id:'9',
        pImg: pImg9,
        nama: 'Nazriel Rizky Pranaja',
        AsalSekolah : 'SMAN Taruna Krida Nusantara',
        sekolahKedinasan : 'IPDN',
        sekdin:'IPDN'
    },
    {
        Id:'10',
        pImg: pImg10,
        nama: 'Khansa Bagaskara Tannady',
        AsalSekolah : 'SMAN 2 Surabaya',
        sekolahKedinasan : 'IPDN',        
        sekdin:'IPDN'
    },
    {
        Id:'11',
        pImg: pImg11,
        nama: 'Sherina Nur Laila',
        AsalSekolah : 'SMAN 2 SIDOARJO',
        sekolahKedinasan : 'IPDN',
        sekdin:'IPDN'
    },
    {
        Id:'12',
        pImg: pImg12,
        nama: 'Kirana Putri Fauzi',
        AsalSekolah : 'SMAN 1 SOOKO',
        sekolahKedinasan : 'IPDN',
        sekdin:'IPDN'
    },
    {
        Id:'13',
        pImg: pImg13,
        nama: 'Jonathan Valentino',
        AsalSekolah : 'SMAN 1 SRENGAT',
        sekolahKedinasan : 'PKN STAN',        
        sekdin:'PKN STAN'
    },
    {
        Id:'14',
        pImg: pImg14,
        nama: 'Diksangga Tentra Samudra',
        AsalSekolah : 'MAN SIDOARJO',
        sekolahKedinasan : 'PKN STAN',
        sekdin:'PKN STAN'
    },
    {
        Id:'15',
        pImg: pImg15,
        nama: 'Erlangga Satrya Utama',
        AsalSekolah : 'SMAN 1 Puri',
        sekolahKedinasan : 'PKN STAN',
        sekdin:'PKN STAN'
    },
    {
        Id:'16',
        pImg: pImg16,
        nama: 'Diska Lyramarsha',
        AsalSekolah : 'SMAN 1 Sidoarjo',
        sekolahKedinasan : 'PKN STAN',        
        sekdin:'PKN STAN'
    },
    {
        Id:'17',
        pImg: pImg17,
        nama: 'Akmal Taqwa Sakti Mahamas Putra',
        AsalSekolah : 'SMAN 3 Surabaya',
        sekolahKedinasan : 'PKN STAN',
        sekdin:'PKN STAN'
    },
    {
        Id:'18',
        pImg: pImg18,
        nama: 'Vinsensia Galuh Dwijayanti',
        AsalSekolah : 'SMAN 16 Surabaya',
        sekolahKedinasan : 'PKN STAN',
        sekdin:'PKN STAN'
    },
    {
        Id:'19',
        pImg: pImg19,
        nama: 'Ahmad Dzaki Mursyidan',
        AsalSekolah : 'SMAN 1 Kota Mojokerto',
        sekolahKedinasan : 'PKN STAN',
        sekdin:'PKN STAN'
    },
    {
        Id:'20',
        pImg: pImg20,
        nama: 'William Arthasasta Kurniadi',
        AsalSekolah : 'SMAN 2 Kediri',
        sekolahKedinasan : 'PKN STAN',        
        sekdin:'PKN STAN'
    },
    {
        Id:'21',
        pImg: pImg21,
        nama: 'Akhmad Maulana Adam Siswanto',
        AsalSekolah : 'SMA Muhammadiyah 1 Panji',
        sekolahKedinasan : 'PKN STAN',
        sekdin:'PKN STAN'
    },
    {
        Id:'22',
        pImg: pImg22,
        nama: 'Hasna Nur Aulia',
        AsalSekolah : 'SMAN 1 Kediri',
        sekolahKedinasan : 'PKN STAN',
        sekdin:'PKN STAN'
    },
    {
        Id:'23',
        pImg: pImg23,
        nama: 'Cahaya Maria Damayanti',
        AsalSekolah : 'SMAN Taruna Nusantara',
        sekolahKedinasan : 'PKN STAN',
        sekdin:'PKN STAN'
    },
    {
        Id:'24',
        pImg: pImg24,
        nama: 'Syifa Naurah Hasanah',
        AsalSekolah : 'SMKN 1 Banjarmasin',
        sekolahKedinasan : 'PKN STAN',        
        sekdin:'PKN STAN'
    },
    {
        Id:'25',
        pImg: pImg25,
        nama: 'Dewi Sartika',
        AsalSekolah : 'SMK TELKOM Malang',
        sekolahKedinasan : 'KEMENHUB',
        sekdin:'KEMENHUB'
    },
    {
        Id:'26',
        pImg: pImg26,
        nama: 'Achmad Aldino Antarizqiansah',
        AsalSekolah : 'SMAN 1 SOOKO',
        sekolahKedinasan : 'KEMENHUB',        
        sekdin:'KEMENHUB'
    },
    {
        Id:'27',
        pImg: pImg27,
        nama: 'Bernad Ananda Susanto',
        AsalSekolah : 'SMAN 1 DLANGGU',
        sekolahKedinasan : 'KEMENHUB',
        sekdin:'KEMENHUB'
    },
    {
        Id:'28',
        pImg: pImg28,
        nama: 'Achmad Effendi',
        AsalSekolah : 'SMAN 1 Kamal',
        sekolahKedinasan : 'KEMENHUB',        
        sekdin:'KEMENHUB'
    },
    {
        Id:'29',
        pImg: pImg29,
        nama: 'Chaleysea Kenzabrina Zahwa ',
        AsalSekolah : 'SMAN 10 Surabaya',
        sekolahKedinasan : 'KEMENHUB',        
        sekdin:'KEMENHUB'
    },
    {
        Id:'30',
        pImg: pImg30,
        nama: 'Nadhira Qurrata',
        AsalSekolah : 'SMAN 1 SIDOARJO',
        sekolahKedinasan : 'KEMENHUB',        
        sekdin:'KEMENHUB'
    },
    {
        Id:'31',
        pImg: pImg31,
        nama: 'Armi Puji Rahayu',
        AsalSekolah : 'SMAN 1 Tunjungan',
        sekolahKedinasan : 'KEMENHUB',        
        sekdin:'KEMENHUB'
    },
    {
        Id:'32',
        pImg: pImg32,
        nama: 'Imam Qurrata',
        AsalSekolah : 'SMA Modal Bangsa',
        sekolahKedinasan : 'STMKG',        
        sekdin:'STMKG'
    },
    {
        Id:'33',
        pImg: pImg33,
        nama: 'Maghfira Izzani Maulani',
        AsalSekolah : 'SMAN 3 Nabire',
        sekolahKedinasan : 'STMKG',        
        sekdin:'STMKG'
    },
    {
        Id:'34',
        pImg: pImg34,
        nama: 'Julianto Saputra Palamba',
        AsalSekolah : 'SMAN 1 Parepare',
        sekolahKedinasan : 'STMKG',        
        sekdin:'STMKG'
    },
    {
        Id:'35',
        pImg: pImg35,
        nama: 'M. Dayyan Febryan',
        AsalSekolah : 'SMAN 2 Baubau',
        sekolahKedinasan : 'STMKG',        
        sekdin:'STMKG'
    },
    {
        Id:'36',
        pImg: pImg36,
        nama: 'Ismail Rafli Achmad',
        AsalSekolah : 'SMAN 1 Tidore ',
        sekolahKedinasan : 'STMKG',        
        sekdin:'STMKG'
    },
    {
        Id:'37',
        pImg: pImg37,
        nama: 'Thalia Salsabila ',
        AsalSekolah : 'SMAN 1 Pangkalpinang',
        sekolahKedinasan : 'STMKG',        
        sekdin:'STMKG'
    },
    {
        Id:'38',
        pImg: pImg38,
        nama: 'Muhammad Yazid Ilmany',
        AsalSekolah : 'SMA Angkasa Adisutjipto',
        sekolahKedinasan : 'STMKG',        
        sekdin:'STMKG'
    },
    {
        Id:'39',
        pImg: pImg39,
        nama: 'Samuel Brema Sihombing ',
        AsalSekolah : 'SMAN 1 Sidikalang',
        sekolahKedinasan : 'STMKG',        
        sekdin:'STMKG'
    },
    {
        Id:'40',
        pImg: pImg40,
        nama: 'Arvian Ayudha',
        AsalSekolah : 'SMA HANG TUAH 2 SIDOARJO',
        sekolahKedinasan : 'UNHAN',        
        sekdin:'UNHAN'
    },
    {
        Id:'41',
        pImg: pImg41,
        nama: 'Ni Putu Setia Gari C',
        AsalSekolah : 'SMA HANG TUAH 5 SIDOARJO',
        sekolahKedinasan : 'UNHAN',        
        sekdin:'UNHAN'
    },
    {
        Id:'42',
        pImg: pImg42,
        nama: 'Luhur Bangun Widjaya',
        AsalSekolah : 'SMA ANTARTIKA SIDOARJO',
        sekolahKedinasan : 'UNHAN',        
        sekdin:'UNHAN'
    },
    {
        Id:'43',
        pImg: pImg43,
        nama: 'Umi Lathifatul',
        AsalSekolah : 'SMA ANTARTIKA SIDOARJO',
        sekolahKedinasan : 'UNHAN',        
        sekdin:'UNHAN'
    },
    {
        Id:'44',
        pImg: pImg44,
        nama: 'Rizki Candra P',
        AsalSekolah : 'SMA ANTARTIKA SIDOARJO',
        sekolahKedinasan : 'UNHAN',        
        sekdin:'UNHAN'
    },
    {
        Id:'45',
        pImg: pImg45,
        nama: 'Galuh Ajeng',
        AsalSekolah : 'SMAN 1 PANDAAN',
        sekolahKedinasan : 'PKN STAN',        
        sekdin:'PKN STAN'
    },
    {
        Id:'46',
        pImg: pImg46,
        nama: 'Zulvia Amelia',
        AsalSekolah : 'SMAN 2 KEDIRI',
        sekolahKedinasan : 'POLTEKIM',        
        sekdin:'POLTEKIM'
    },
    {
        Id:'47',
        pImg: pImg47,
        nama: 'Arikhun Anas',
        AsalSekolah : 'SMAN 2 PARE, KEDIRI',
        sekolahKedinasan : 'POLTEKIP',        
        sekdin:'POLTEKIP'
    },
    {
        Id:'48',
        pImg: pImg48,
        nama: 'Reyhan Forbes Imani',
        AsalSekolah : 'SMAN 10 Malang',
        sekolahKedinasan : 'POLTEKIP',        
        sekdin:'POLTEKIP'
    },
    {
        Id:'49',
        pImg: pImg49,
        nama: 'M. Taufiqul Hafidz',
        AsalSekolah : 'SMAN Taruna Brawijaya',
        sekolahKedinasan : 'POLTEKIP',        
        sekdin:'POLTEKIP'
    },
    {
        Id:'50',
        pImg: pImg50,
        nama: 'Yonathan Niko Wisata ',
        AsalSekolah : 'SMAN 1 Puri Mojokerto',
        sekolahKedinasan : 'POLTEKIP',        
        sekdin:'POLTEKIP'
    },
    {
        Id:'51',
        pImg: pImg51,
        nama: 'Ully Prisca',
        AsalSekolah : 'SMAN 1 KEDUNGWARU',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'52',
        pImg: pImg52,
        nama: 'Devina Salsabila',
        AsalSekolah : 'SMAN 3 BLITAR',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'53',
        pImg: pImg53,
        nama: 'Dewi Sofiatul',
        AsalSekolah : 'SMAN SIDOARJO',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'54',
        pImg: pImg54,
        nama: 'Baran Hidayat Azzahra',
        AsalSekolah : 'MAN 1 KEDIRI',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'55',
        pImg: pImg55,
        nama: 'M Zaqi Arif',
        AsalSekolah : 'SMAN SURABAYA',
        sekolahKedinasan : 'KEMENHUB',
        sekdin:'KEMENHUB'
    },
    {
        Id:'56',
        pImg: pImg56,
        nama: 'Widya Khoirun Nissa',
        AsalSekolah : 'SMAN 2 Pare',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'57',
        pImg: pImg57,
        nama: 'Devano Arya Pratama',
        AsalSekolah : 'SMAN 1 GRESIK',
        sekolahKedinasan : 'KEMENHUB',        
        sekdin:'KEMENHUB'
    },
    {
        Id:'58',
        pImg: pImg58,
        nama: 'Faiz Aqil Majid',
        AsalSekolah : 'SMAN 2 Kota Kediri',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'59',
        pImg: pImg59,
        nama: 'Alfredo Fairuz Cleo Arifin',
        AsalSekolah : 'SMAN 1 Waru',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'60',
        pImg: pImg60,
        nama: 'Hafidz Muzzacky Ar Rizky',
        AsalSekolah : 'SMAN 1 Puri',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'61',
        pImg: pImg61,
        nama: 'Dita Ayu K',
        AsalSekolah : 'SMAN 1 PORONG',
        sekolahKedinasan : 'PKN STAN',        
        sekdin:'PKN STAN'
    },
    {
        Id:'62',
        pImg: pImg62,
        nama: 'Dimas Deviano',
        AsalSekolah : 'SMAN 1 SIDOARJO',
        sekolahKedinasan : 'PKN STAN',        
        sekdin:'PKN STAN'
    },
    {
        Id:'63',
        pImg: pImg63,
        nama: 'Mahardika Bayu',
        AsalSekolah : 'SMAN 3 KEDIRI',
        sekolahKedinasan : 'PKN STAN',        
        sekdin:'PKN STAN'
    },
    {
        Id:'64',
        pImg: pImg64,
        nama: 'Belinda Hevi Apta H',
        AsalSekolah : 'SMAN 1 WARU',
        sekolahKedinasan : 'KEMENHUB',        
        sekdin:'KEMENHUB'
    },
    {
        Id:'65',
        pImg: pImg65,
        nama: 'Adam Fawwaz Alan S',
        AsalSekolah : 'SMAN 20 SURABAYA',
        sekolahKedinasan : 'KEMENHUB',        
        sekdin:'KEMENHUB'
    }
]

export default Project;

/*
 {
        Id:'66',
        pImg: pImg2,
        nama: 'Dwi Prasetyo',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'67',
        pImg: pImg1,
        nama: 'Dwi Susanto',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'68',
        pImg: pImg2,
        nama: 'Dwi Prasetyo',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {  
        Id:'69',
        pImg: pImg1,
        nama: 'Dwi Susanto',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'70',
        pImg: pImg2,
        nama: 'Dwi Prasetyo',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'71',
        pImg: pImg1,
        nama: 'Dwi Susanto',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'72',
        pImg: pImg2,
        nama: 'Dwi Prasetyo',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {  
        Id:'73',
        pImg: pImg1,
        nama: 'Dwi Susanto',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'74',
        pImg: pImg2,
        nama: 'Dwi Prasetyo',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'75',
        pImg: pImg1,
        nama: 'Dwi Susanto',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'76',
        pImg: pImg2,
        nama: 'Dwi Prasetyo',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'77',
        pImg: pImg1,
        nama: 'Dwi Susanto',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'78',
        pImg: pImg2,
        nama: 'Dwi Prasetyo',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'79',
        pImg: pImg1,
        nama: 'Dwi Susanto',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'80',
        pImg: pImg2,
        nama: 'Dwi Prasetyo',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'81',
        pImg: pImg1,
        nama: 'Dwi Susanto',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'82',
        pImg: pImg2,
        nama: 'Dwi Prasetyo',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'83',
        pImg: pImg1,
        nama: 'Dwi Susanto',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'84',
        pImg: pImg2,
        nama: 'Dwi Prasetyo',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    },
    {
        Id:'85',
        pImg: pImg1,
        nama: 'Dwi Susanto',
        AsalSekolah : 'SMAN 1 Gondang',
        sekolahKedinasan : 'POLSTAT STIS',        
        sekdin:'POLSTAT STIS'
    }*/