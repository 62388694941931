import sImg1 from '../images/services/sekdin2.png'
import sImg2 from '../images/services/unhan.png'
import sImg3 from '../images/services/cpns.png'
import sImg4 from '../images/services/akmil.png'
import sImg5 from '../images/services/kedinasan_online.png'


const Services = [
    {
        Id: '1',
        sImg:sImg1,
        title: 'Program Persiapan Sekolah Kedinasan',
        slug: 'program-kedinasan',
        thumb1:'Kelas XI',
        thumb2:'Kelas XII',
        thumb3:'SMA/SMK',
        col:'col-lg-6',
        description:'Visit new places to discover with a Tourist Visa. We deliver your documents ...',
    },
    {
        Id: '2',
        sImg:sImg2,
        title: 'Program Persiapan Universitas Pertahanan',
        slug: 'program-unhan',
        thumb1:'Kelas XII',
        thumb2:'SMA/SMK',
        col:'col-lg-6',
        description:'Developing your trade, setting up new sales channels Your visa is ready...',
    },
    {
        Id: '3',
        sImg:sImg3,
        title: 'Program Persiapan CPNS',
        slug: 'program-cpns',
        thumb1:'Alumni',
        thumb2:'Mahasiswa',
        col:'col-lg-4',
        description:'Developing your trade, setting up new sales channels Your visa is ready...',
    },
    {
        Id: '4',
        sImg:sImg4,
        title: 'Program Persiapan AKMIL & AKPOL',
        slug: 'program-akmil-akpol',
        thumb1:'SMA Kelas XII',
        thumb2:'Alumni',
        col:'col-lg-4',
        description:'Embarking on a journey of higher education in a foreign country opens doors to...',
    },
    {
        Id: '5',
        sImg:sImg5,
        title: 'Program Online Class',
        slug: 'online-class',
        thumb1:'Kelas XII',
        thumb2:'Alumni',
        col:'col-lg-4',
        description:'Expert Guidance for a Seamless Immigration Journey Expert Guidance...',
    },
]    

export default Services;