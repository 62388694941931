import React, { Fragment, useState } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import ModalVideo from 'react-modal-video'
import CtaSection from '../../components/CtaSection/CtaSection';
import PolicySection from './Policy';
import offerBanner from '../../images/icons/best_offer.svg.svg';
import PartnerSection from '../../components/PartnerSection';


const PricingPage = (props) => {

    const [isOpen, setOpen] = useState(false)

    const [isActive, setIsActive] = useState(false);

    const handleToggle = () => {
        setIsActive(!isActive);
    };


    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Program Kedinasan DELTA'} pagesub={'Pricelist'} pageTop={'Daftar'} />
                <PolicySection />
                <section className="pricing_section section_space pb-0 bg-light">
                    <div className="container">
                        <div className="heading_block text-center">
                            <h2 className="heading_text mb-0">
                                Program Persiapan Sekolah Kedinasan 2025 & 2026
                            </h2>
                        </div>
                        <div className="pricing_toggle_btn text-center">
                            <button type="button" onClick={handleToggle} className={isActive ? 'active' : ''}>
                                <span>Kelas XI <small>2026</small></span>
                                <span>Kelas XII <small>2025</small></span>
                            </button>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className={`pricing_block ${isActive ? 'active' : ''}`}>
                                    <div className="table_head">
                                        <div className="pricing_price_value bg-primary-subtle text-primary">
                                            <span className="pricing_monthly">
                                                <small> <b>15.800.000</b></small> <sub>Hubungi Admin untuk Informasi Promo</sub>
                                            </span>
                                            <span className="pricing_annually">
                                                <small><b>8.800.000</b></small> <sub>Hubungi Admin untuk Informasi Promo</sub>
                                            </span>
                                        </div>
                                        <div className="pricing_block_title">
                                            <h3 className="pricing_package_title">Program Reguler</h3>
                                            <p className="pricing_package_description mb-0">
                                             Program Dasar dengan total pertemuan up to 180x pertemuan
                                            </p>
                                        </div>
                                    </div>
                                    <ul className="icon_list unordered_list_block">
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                36x++ Tryout SKD
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Konsultasi Materi Terjadwal
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                150x Total Pertemuan
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Bimbingan Fisik.
                                            </span>
                                        </li>
                                        <li className="delete">
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Modul Intensif DELTA.
                                            </span>
                                        </li>
                                        <li className="delete">
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Kelas Psikotest & Wawancara.
                                            </span>
                                        </li>
                                    </ul>
                                    <a className="btn btn-light" href="https://desty.page/deltabimbel">
                                        <span className="btn_label" data-text="DAFTAR SEKARANG">DAFTAR SEKARANG</span>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={`pricing_block ${isActive ? 'active' : ''}`}>
                                    <div className="table_head">
                                        <div className="pricing_price_value bg-primary-subtle text-primary">
                                            <span className="pricing_monthly">
                                            <small> <b>20.800.000</b></small> <sub>Hubungi Admin untuk Informasi Promo</sub>
                                            </span>
                                            <span className="pricing_annually">
                                            <small> <b>11.800.000</b></small> <sub>Hubungi Admin untuk Informasi Promo</sub>
                                            </span>
                                        </div>
                                        <div className="pricing_block_title">
                                            <h3 className="pricing_package_title">Program Exclusive</h3>
                                            <p className="pricing_package_description mb-0">
                                                Program dengan fasilitas terlengkap dengan total pertemuan Up To 300 kali pertemuan
                                            </p>
                                        </div>
                                    </div>
                                    <ul className="icon_list unordered_list_block">
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                36x++ Tryout SKD
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Konsultasi Materi Terjadwal
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                300x Total Pertemuan
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Bimbingan Fisik.
                                            </span>
                                        </li>
                                        <li className="icon_list_icon">
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Modul Intensif DELTA.
                                            </span>
                                        </li>
                                        <li className="icon_list_icon">
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Kelas Psikotest & Wawancara.
                                            </span>
                                        </li>
                                    </ul>
                                    <a className="btn btn-light" href="https://desty.page/deltabimbel">
                                        <span className="btn_label" data-text="DAFTAR SEKARANG">DAFTAR SEKARANG</span>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={`pricing_block ${isActive ? 'active' : ''}`}>
                                    <div className="best_plan">
                                        <img src={offerBanner} alt="Best Offer" />
                                    </div>
                                    <div className="table_head">
                                        <div className="pricing_price_value bg-primary-subtle text-primary">
                                            <span className="pricing_monthly">
                                            <small> <b>23.800.000</b></small> <sub>Hubungi Admin untuk Informasi Promo</sub>
                                            </span>
                                            <span className="pricing_annually">
                                            <small> <b>14.800.000</b></small> <sub>Hubungi Admin untuk Informasi Promo</sub>
                                            </span>
                                        </div>
                                        <div className="pricing_block_title">
                                            <h3 className="pricing_package_title">Program Super Exclusive</h3>
                                            <p className="pricing_package_description mb-0">
                                                Program dengan <b>JAMINAN FREE BIMBEL 1 TAHUN</b> apabila tidak lolos seleksi
                                            </p>
                                        </div>
                                    </div>
                                    <ul className="icon_list unordered_list_block">
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                36x++ Tryout SKD
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Konsultasi Materi Terjadwal
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                300x Total Pertemuan
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Bimbingan Fisik.
                                            </span>
                                        </li>
                                        <li className="icon_lisst_icon">
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Modul Intensif DELTA.
                                            </span>
                                        </li>
                                        <li className="icon_list_icon">
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Kelas Psikotest & Wawancara.
                                            </span>
                                        </li>
                                    </ul>
                                    <a className="btn btn-light" href="https://desty.page/deltabimbel">
                                        <span className="btn_label" data-text="DAFTAR SEKARANG">DAFTAR SEKARANG</span>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="client_logo_section section_space bg-light">
                    <div className="container">
                        <div className="heading_block text-center">
                            <div className="heading_focus_text mb-0">
                                <span className="badge bg-secondary text-white">Sekolah Kedinasan</span>
                                Yang Telah Diloloskan DELTA
                            </div>
                        </div>
                        <PartnerSection/>
                    </div>
                </div>
            </main>
            
            <CtaSection />
            <Footer />
            <Scrollbar />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
        </Fragment>
    )
};
export default PricingPage;
