import React from 'react';
import sIcon1 from '../../images/icons/icon_clock.svg'
import sIcon2 from '../../images/icons/icon_dart_board_2.svg'
import sIcon3 from '../../images/icons/icon_target.svg'

const Policy = [
    {
        title: 'Our History',
        subTitle: 'Kami berdiri sejak tahun 2017 dengan nama deltaSTAN yang khusus membantu siswa untuk lolos PKN STAN, namun seiring berjalannya waktu kami juga membantu para siswa menuju ke berbagai sekolah kedinasan. Kini DELTA telah memiliki 4 cabang di kota yaitu Sidoarjo, Surabaya, Mojokerto, dan Kediri',
        icon: sIcon1,
    },
    {
        title: 'Our Mission',
        subTitle: 'Kami Bertujuan untuk membantu siswa-siswi yang ingin mewujudkan cita-citanya menjadi abdi negara melalui Sekolah Kedinasan maupun AKMIL/AKPOL. Tidak hanya dipersiapkan dari latihan akademik namun berbagai tahapan seleksi lain juga kami persiapkan untuk mencapai Sekdin yang diinginkan mereka',
        icon: sIcon2,
    },
    {
        title: 'Our Vision',
        subTitle: 'Kami melihat seiring berjalannya waktu semakin sulit mencari pekerjaan yang layak. Sekolah Kedinasan menawarkan benefit dimana para alumni nya langsung diangkat menjadi ASN setelah lulus. Hal ini menyebabkan demand Sekdin semakin tinggi sehingga diperlukan persiapan yang matang untuk bisa lolos seleksi Sekdin',
        icon: sIcon3,
    },


]


const PolicySection = (props) => {

    return (
        <section className="policy_section bg-light">
            <div className="container">
                <div className="row">
                    {Policy.map((policy, pitem) => (
                        <div className="col-lg-4" key={pitem}>
                            <div className="iconbox_block">
                                <div className="iconbox_icon">
                                    <img src={policy.icon} alt="Dollar SVG Icon" />
                                </div>
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">{policy.title}</h3>
                                    <p className="mb-0">
                                        {policy.subTitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PolicySection;