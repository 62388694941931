import React, { useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom"; // React Router Link for internal links
import Button from "@mui/material/Button"; // Material-UI Button for styling

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/home',
    },
    {
        id: 3,
        title: 'Program',
        link: '/service',
        submenu: [
            { id: 31, title: 'Program Sekolah Kedinasan', link: '/service-single/program-kedinasan' },
            { id: 3222, title: 'Program CPNS', link: '/service-single/program-cpns' },
            { id: 322, title: 'Program Persiapan UNHAN', link: '/service-single/program-unhan' },
            { id: 345, title: 'Program Akmil & Akpol', link: '/service-single/program-akmil-akpol' },
            { id: 3454, title: 'Program Online Class', link: '/service-single/online-class' }
        ]
    },
    {
        id: 4,
        title: 'Alumni',
        link: '/alumni',
        submenu: [
            { id: 41, title: 'Alumni Sekolah Kedinasan', link: '/alumni' },
            { id: 42, title: 'Alumni CPNS', link: '/alumni' }
        ]
    },
    {
        id: 88,
        title: 'Tentang Kami',
        link: '/about',
    },
    {
        id: 9,
        title: 'Contact',
        link: '/contact',
    },
    {
        id: 10,
        title: 'Login Siswa',
        link: 'https://online.delta-stan.com', // External link
    }
];

const MobileMenu = () => {
    const [openId, setOpenId] = useState(0);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <ul className="main_menu_list clearfix">
            {menus.map((item) => (
                <ListItem key={item.id} className={item.id === openId ? 'active' : ''}>
                    {item.submenu ? (
                        <>
                            <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>
                                {item.title}
                                <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'} />
                            </p>
                            <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                <List className="subMenu">
                                    {item.submenu.map((submenu) => (
                                        <ListItem key={submenu.id}>
                                            <Link onClick={ClickHandler} to={submenu.link}>
                                                {submenu.title}
                                            </Link>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </>
                    ) : item.id === 10 ? (
                        // Render "Login Siswa" as a Material-UI Button
                        <Button 
                            component="a" 
                            href={item.link} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            variant="contained" 
                            color="primary"
                            sx={{
                                display: 'block', // Makes it a block-level element (centers)
                                width: '200px', // Width of the button
                                margin: '0 auto', // Centers horizontally
                                borderRadius: '25px', // More rounded corners
                                padding: '10px 20px', // Padding for better button size
                                fontWeight: 'bold', // Make text bold
                                textAlign: 'center', // Center text
                                backgroundColor: '#007bff', // Button background color
                                color: 'white', // Ensure button text is white
                                '& .MuiButton-label': {
                                    color: 'white', // Override the label text color to white
                                },
                                '&:hover': {
                                    backgroundColor: '#0056b3', // Darker hover background
                                }
                            }}
                        >
                            {item.title}
                        </Button>
                    ) : (
                        // Use Link for other internal items
                        <Link onClick={ClickHandler} to={item.link}>
                            {item.title}
                        </Link>
                    )}
                </ListItem>
            ))}
        </ul>
    );
};

export default MobileMenu;
